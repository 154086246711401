import React, { FC, Fragment, FunctionComponent, ImgHTMLAttributes, Ref } from 'react';
import ImageLoading, { LoadingPlaceholder } from 'react-image-loading';
import styled from 'styled-components';
import placeholder from '../../images/placeholder.svg';
import { addWebpToSrcSet } from '../../utils/addWebpToSrcSet';
import { useInView } from 'react-intersection-observer';

export interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
    // the ratio of the image. can be calculated by using height divided by width.
    // this will only ensure the placeholders fill up the correct space.
    // you are still responsible for correctly setting the width of the container.
    ratio: number;
}

const Placeholder = styled.img.attrs({ src: placeholder })`
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
`;

const Image = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    object-fit: contain;
    object-position: top left;
`;

const Container = styled.figure<{ ratio: number }>`
    position: relative;
    margin: 0;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }
`;

const LoaderContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
`;

const Loader: FunctionComponent<{ loading: boolean; ref: Ref<HTMLDivElement> }> = React.forwardRef(
    ({ loading }, ref) => (
        <LoaderContainer ref={ref}>
            <LoadingPlaceholder style={{ transition: 'opacity 0.5s', opacity: loading ? 1 : 0 }} animate={loading} />
        </LoaderContainer>
    )
);

const Img: FC<ImgProps> = ({ ratio, className, ...props }) => {
    const webpCompatible = props.src && props.src.search(/(\jpg|png)/g) > -1;
    const webpSrcSet = webpCompatible && props.srcSet && addWebpToSrcSet(props.srcSet);

    const [containerRef, inView] = useInView({
        triggerOnce: true,
    });

    return (
        <Container ratio={ratio} className={className} ref={containerRef}>
            {inView && (
                <ImageLoading>
                    {(ref, status) =>
                        status === 'error' || !props.src ? (
                            <Placeholder />
                        ) : (
                            <Fragment>
                                <picture>
                                    {webpSrcSet && <source type="image/webp" srcSet={webpSrcSet} />}
                                    <Image ref={ref} {...props} />
                                </picture>
                                <Loader ref={ref} loading={status === 'loading'} />
                            </Fragment>
                        )
                    }
                </ImageLoading>
            )}
        </Container>
    );
};

export default Img;
