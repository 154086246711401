import { ResolutionSpec } from '../entities/ResolutionSpec';

export const numberToResolutionSpec = (spec: ResolutionSpec | number): ResolutionSpec =>
    typeof spec === 'number'
        ? {
              s: spec,
              m: spec,
              l: spec,
              xl: spec,
          }
        : spec;
