import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { hideOnVertical } from '../../constants/css/hideOnVertical';
import theme from '../../constants/theme';
import { ResolutionSpec } from '../../entities/ResolutionSpec';
import { numberToResolutionSpec } from '../../utils/numberToResolutionColumns';

interface Props extends HTMLAttributes<HTMLDivElement> {
    columns?: ResolutionSpec | number;
    marginRight?: ResolutionSpec | number;
    displayOnVertical?: boolean;
}

/**
 * Grid with optional columns and marginRight props.
 * Optionally set a width, normally the grid will scale to its child cells
 * Use the `Cell` suffix for Grid children for clarity.
 */
const Grid = React.forwardRef<HTMLDivElement, Props>(({ columns, marginRight, children, ...rest }, ref) => {
    const computedColumns: ResolutionSpec | undefined =
        columns !== undefined ? numberToResolutionSpec(columns) : columns;

    const computedMarginRight: ResolutionSpec | undefined =
        marginRight !== undefined ? numberToResolutionSpec(marginRight) : marginRight;

    return (
        <StandardGrid columns={computedColumns} marginRight={computedMarginRight} {...rest} ref={ref}>
            {children}
        </StandardGrid>
    );
});

interface StyledGridProps {
    columns?: ResolutionSpec;
    marginRight?: ResolutionSpec;
    displayOnVertical?: boolean;
}

const StandardGrid = styled.div<StyledGridProps>`
    z-index: 1;
    ${({ displayOnVertical = true }) => !displayOnVertical && hideOnVertical};

    @media (${theme.mediaQueries.horizontal}) {
        display: grid;
        grid-auto-columns: 5vw;
        grid-template-rows: repeat(30, 1fr);
        padding-right: ${({ marginRight }) => (marginRight ? marginRight.s * 5 : 0)}vw;
        ${({ columns }) => (columns ? `grid-template-columns: repeat(${columns.s}, 5vw) ` : '')};
    }

    @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.m}) {
        padding-right: ${({ marginRight }) => (marginRight ? marginRight.m * 5 : 0)}vw;
        ${({ columns }) => (columns ? `grid-template-columns: repeat(${columns.m}, 5vw); ` : '')}
    }

    @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.l}) {
        padding-right: ${({ marginRight }) => (marginRight ? marginRight.l * 5 : 0)}vw;
        ${({ columns }) => (columns ? `grid-template-columns: repeat(${columns.l}, 5vw); ` : '')}
    }
    @media (${theme.mediaQueries.horizontal}) and (min-height: ${theme.mediaQueries.height.xl}) {
        padding-right: ${({ marginRight }) => (marginRight ? marginRight.xl * 5 : 0)}vw;
        ${({ columns }) => (columns ? `grid-template-columns: repeat(${columns.xl}, 5vw); ` : '')}
    }

    @media (${theme.mediaQueries.vertical}) {
        display: flex;
        flex-direction: column;
        padding-right: 0;
    }
`;

export default Grid;
