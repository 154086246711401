import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../constants/theme';
import { CTAContainer } from '../molecules/CTA';

export interface SectionProps extends HTMLAttributes<HTMLElement> {
    background?: string;
    fontColor?: string;
    paddingLeft?: number;
    paddingRight?: number;
    marginLeft?: number;
    as?: keyof JSX.IntrinsicElements;
    variant?: 'default' | 'footer';
    leadingCTA?: boolean;
    endingCTA?: boolean;
    topMargin?: boolean;
    elevate?: boolean;
}
export const Section: FC<SectionProps> = ({ variant = 'default', ...rest }) => {
    const background = variant === 'footer' ? theme.colors.athensGray : rest.background;
    return (
        <Container
            {...rest}
            variant={variant}
            background={background}
            as={variant === 'footer' ? 'footer' : undefined}
            data-background={background || 'none'}
            id={variant === 'footer' ? 'footer' : undefined}
        />
    );
};

const DefaultVariant = css<SectionProps>`
     @media (${theme.mediaQueries.horizontal}) {
        ${({ elevate }) => elevate && `z-index: 1;`}
        flex-direction: row;
        margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft * 5}vw` : 0)};
        padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft * 5}vw` : 0)};
        padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight * 5}vw` : 0)};
        ${({ background }) =>
            !background &&
            `&::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: ${theme.colors.white};
        }`}
    }
    @media (${theme.mediaQueries.vertical}) {
        padding: 5rem 2.5rem;
        flex-direction: column;

        ${({ leadingCTA }) => leadingCTA && `margin-top: -5rem;`}

        ${({ endingCTA }) => endingCTA && `margin-bottom: -5rem;`}

        ${({ background }) =>
            !background &&
            `&::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: ${theme.colors.white};
        }`}

        ${({ background }) => css`
            &[data-background="${background || 'none'}"] + &[data-background="${background || 'none'}"] {
                padding-top: 0;
            }
        `}

        ${({ background }) =>
            background &&
            css`
                ${CTAContainer}:last-child {
                    // let cta eat up bottom padding
                    margin-bottom: -5rem;
                    left: 0;
                    position: relative;
                }
            `}

        > * + * {
            padding-top: 5rem;
        }
    }
`;

const FooterVariant = css<SectionProps>`
    @media screen and (${theme.mediaQueries.horizontal}) {
        margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft * 5}vw` : 0)};
        padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft * 5}vw` : 0)};
        padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight * 5}vw` : 0)};
    }
    @media screen and (${theme.mediaQueries.vertical}) {
        padding: 5rem 2.5rem;
        ${({ topMargin }) =>
            topMargin ? `padding-top: 17rem; margin-top: 1rem;` : `padding-top: 6rem; margin-top: 10rem;`}
        z-index: 0;
        flex-direction: column;
        &::before {
            content: '';
            position: absolute;
            display: block;
            background: inherit;
            z-index: -1;
            width: 130vw;
            height: 13rem;
            margin-top: -3rem;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 0;
            top: 0;
            left: 50%;
            transform: translate(-50%, -10px);
        }

        > [type='Craft_CTAContact'] {
            @media screen and (${theme.mediaQueries.vertical}) {
                margin-top: -15rem;
                margin-bottom: 3em;
            }
        }
    }
`;

const Container = styled.section<SectionProps>`
    display: flex;
    position: relative;
    background: ${({ background }) => (background ? background : 'transparent')};
    color: ${({ fontColor }) => (fontColor ? fontColor : theme.colors.black)};

    ${({ variant, background }) => {
        switch (variant) {
            case 'footer':
                return FooterVariant;
            default:
                return DefaultVariant;
        }
    }}
`;
